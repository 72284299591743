/* You can add global styles to this file, and also import other style files */
@use '../../../libs/utils/ng-shared-components/src/assets/theme.scss';
@use './assets/themes/components/datepicker';
@use './assets/themes/components/button';
@use './assets/themes/components/checkbox';
@use '@angular/material' as mat;
@use './assets/themes/colors';
@use 'prismjs/themes/prism.css';

@include mat.core();

html {
  overflow-y: scroll;
  overflow-x: hidden;
}

html,
body {
  @include mat.core-theme(theme.$light-theme);
  @include mat.all-component-themes(theme.$light-theme);
  
  & {
    height: 100%;
    background-color: #faf7f5;
  }
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;

  &.dark-mode {
    background-color: colors.$color-black;
    color: colors.$color-white;
    // @include mat.all-component-themes(theme.$dark-theme);
  }
}

.modal-animation {
  animation-duration: 0.5s;
  animation-name: slideInRight;

  .mat-mdc-dialog-surface {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel {
  padding: 15px;
}

@keyframes slideInRight {
  0% {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px colors.$color-white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: colors.$color-primary;
  border-radius: 3px;
}

@mixin mat-menu-size($width, $height) {
  width: $width !important;
  max-width: none !important;
  max-height: $height !important;
  overflow: hidden;
}

.notification-menu {
  @include mat-menu-size(540px, 85vh);
}

::ng-deep .notification-menu::-webkit-scrollbar-thumb {
  background-color: red;
}
