@use '../colors';

.mat-mdc-raised-button.mat-primary {
  &:not(:disabled) {
    color: colors.$color-white;
    background-color: colors.$color-primary;
  }
}

.dark-mode {
  .mat-mdc-raised-button.mat-primary {
    &:not(:disabled) {
      color: colors.$color-black;
      background-color: colors.$color-primary;
    }
  }
}