@use '../colors';

.mdc-checkbox__checkmark {
  &:not(:disabled) {
    color: colors.$color-white !important;
  }
}

.dark-mode {
  .mdc-checkbox__checkmark {
    &:not(:disabled) {
      color: colors.$color-black !important;
    }
  }
}